import cn from 'classnames';
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  NextPage,
} from 'next';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

import API from '~/api';
import {
  ContractDetail,
  Head,
  // InstantAlert,
  Layout,
  LiveMints,
  MintsOverview,
  Toaster,
} from '~/components';
import config from '~/config';
import {
  DEFAULT_MAIN_LAYOUT_ORDER,
  MAIN_LAYOUT,
  X_CONNECTION_FAILED_STATEMENT,
} from '~/constants';
import useGasSuggestion from '~/data/useGasSuggestion';
import useCheckAppVersion from '~/hooks/useCheckAppVersion';
import useIsMounted from '~/hooks/useIsMounted';
import { appSettingModalState, displayState } from '~/store/app';
import {
  checksumContractAddressState,
  contractAddressState,
} from '~/store/contract';
import type { AvailableChain } from '~/types';
import extractXHandle from '~/utils/extractXHandle';
import toChecksumAddress from '~/utils/toChecksumAddress';

import styles from './collection.module.scss';

interface CollectionPageProps {
  chain: AvailableChain | null;
  description: string | null;
  imageUrl: string | null;
  searchedAddress: string | null;
  title: string | null;
  xHandle: string | null;
}

export const getServerSideProps: GetServerSideProps<
  CollectionPageProps
> = async ({ query }: GetServerSidePropsContext) => {
  try {
    const address = query.address as string | undefined;
    if (!address) throw new Error();

    const response = await API.getContractDetail(address);
    const contract = response.data;
    const chain = contract.chain ?? null;
    const title = contract.name ?? null;
    const description = contract.description ?? null;
    const ogParams: { [key: string]: any } = {
      address,
    };
    const xHandle = extractXHandle(contract.twitterUrl);

    if (chain) ogParams['chain'] = chain;
    if (contract.imageUrl) ogParams['image'] = contract.imageUrl;
    if (contract.isVerified) ogParams['isVerified'] = contract.isVerified;
    if (contract.maxSupply.length > 0) {
      ogParams['maxSupply'] = contract.maxSupply[0].supply;
    }
    if (title) ogParams['title'] = title;
    if (contract.standard) ogParams['standard'] = contract.standard;
    if (xHandle) ogParams['twitterHandle'] = `@${xHandle}`;
    if (
      contract.canInteractFromContract !== null &&
      contract.canInteractFromContract === false
    )
      ogParams['unbottable'] = true;

    const imageUrl = `${
      config.APP_IMAGE_HOST
    }/og-images?template=contract&${new URLSearchParams(ogParams).toString()}`;

    return {
      props: {
        chain,
        description,
        imageUrl,
        searchedAddress: address,
        title,
        xHandle,
      },
    };
  } catch (e) {
    return {
      props: {
        chain: null,
        description: null,
        imageUrl: null,
        searchedAddress: null,
        title: null,
        xHandle: null,
      },
    };
  }
};

const CollectionPage: NextPage<CollectionPageProps> = ({
  chain,
  description,
  imageUrl,
  searchedAddress,
  title,
  xHandle,
}) => {
  useCheckAppVersion();
  const mainLayoutOrder =
    useRecoilValue(displayState)?.main || DEFAULT_MAIN_LAYOUT_ORDER;
  const setContractAddress = useSetRecoilState(contractAddressState);
  const contractAddress = useRecoilValue(checksumContractAddressState);
  const contractDetailContainer = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const setAppSettingModal = useSetRecoilState(appSettingModalState);
  const isMounted = useIsMounted();

  useGasSuggestion();

  useIsomorphicLayoutEffect(() => {
    // for redirect url from x authentication
    const hashUrls = router.asPath.match(/#([a-z0-9_-]+)/gi);
    if (hashUrls && hashUrls[0] === '#connect-twitter') {
      const result = router.asPath.match(/\?success=([a-z]+)/)?.[1];
      if (result === 'true') {
        setAppSettingModal({ history: [5], isOpened: true });
      } else {
        setTimeout(() =>
          Toaster.toast({
            description: X_CONNECTION_FAILED_STATEMENT,
            type: 'error',
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (router.isReady) {
      const regex = new RegExp(/[^a-zA-Z0-9 ]/g);
      let addressInQuery = router.query.address as string;
      const chainInQuery = router.query.chain as Lowercase<AvailableChain>;

      if (regex.test(addressInQuery)) {
        addressInQuery = addressInQuery.replace(regex, '');
        router.replace(
          {
            pathname: `/collection/[chain]/[address]`,
            query: { address: addressInQuery, chain: chainInQuery },
          },
          undefined,
          {
            shallow: true,
          }
        );
      } else {
        setContractAddress(toChecksumAddress(addressInQuery));
      }
    }
  }, [router.isReady, router.query]);

  useEffect(() => {
    if (contractAddress?.length > 0) {
      // scroll to top if contract address is changed
      if (contractDetailContainer.current) {
        contractDetailContainer.current.scrollTop = 0;
      }
    }
  }, [contractAddress]);

  return (
    <>
      {
        // set seo for ssr
        searchedAddress ? (
          <Head
            description={description}
            imageUrl={imageUrl}
            title={title}
            url={`${config.APP_HOST}/collection/${chain}/${searchedAddress}`}
          >
            {xHandle && <meta name="twitter:creator" content={xHandle} />}
          </Head>
        ) : (
          <Head />
        )
      }
      <Layout isLoading={!isMounted}>
        <div className={styles.container}>
          {/* <InstantAlert
            message={`We're having an issue with pending transaction numbers. We are working on a fix. Thank you for your patience.`}
          /> */}
          {mainLayoutOrder.map(
            (name, index) =>
              ({
                [MAIN_LAYOUT.CONTRACT_DETAIL]: (
                  <main
                    className={cn(styles.contract_detail_container, {
                      [styles.is_center]:
                        mainLayoutOrder?.[1] === MAIN_LAYOUT.CONTRACT_DETAIL,
                    })}
                    ref={contractDetailContainer}
                    key={`${name}_${index}`}
                  >
                    <ContractDetail />
                  </main>
                ),
                [MAIN_LAYOUT.LIVE_MINTS]: (
                  <LiveMints key={`${name}_${index}`} />
                ),
                [MAIN_LAYOUT.MINTS_OVERVIEW]: (
                  <MintsOverview key={`${name}_${index}`} />
                ),
              }[name])
          )}
        </div>
      </Layout>
    </>
  );
};

export default CollectionPage;
